import { SVGProps, Ref, forwardRef } from 'react'

const SvgDocumentReady = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.72 1.72a.75.75 0 0 1 .53-.22h10.629a.75.75 0 0 1 .53.22L16.28 4.59c.141.14.22.331.22.53V7.5a.75.75 0 0 0 1.5 0V5.121a2.25 2.25 0 0 0-.659-1.59L14.47.659A2.25 2.25 0 0 0 12.88 0H2.25A2.25 2.25 0 0 0 0 2.25v16.5A2.25 2.25 0 0 0 2.25 21H7.5a.75.75 0 0 0 0-1.5H2.25a.75.75 0 0 1-.75-.75V2.25a.75.75 0 0 1 .22-.53ZM3 6.75A.75.75 0 0 1 3.75 6h10.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75Zm14.25 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5Zm-3.712 3.038a5.25 5.25 0 1 1 7.424 7.424 5.25 5.25 0 0 1-7.424-7.424Zm6.987 2.418a.75.75 0 0 0-1.2-.9l-2.904 3.872h-.004L14.92 17.43a.75.75 0 0 0-1.06 1.06l1.5 1.5v.002a1.502 1.502 0 0 0 2.26-.162v-.001l2.906-3.873ZM3.75 10.5a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5h-6ZM3 15.75a.75.75 0 0 1 .75-.75H7.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
      clipRule="evenodd"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgDocumentReady)
export default ForwardRef
